<template>
  <v-navigation-drawer
    v-model="primaryDrawer.model"
    :permanent="primaryDrawer.type === 'permanent'"
    :temporary="primaryDrawer.type === 'temporary'"
    :clipped="primaryDrawer.clipped"
    :floating="primaryDrawer.floating"
    :mini-variant="primaryDrawer.mini"
    color="white"
    width="265"
    fixed
    app
    scrollable
  >
    <v-toolbar flat>
      <v-list style="background-color: white !important;">
        <v-list-tile >
          <v-list-tile-title style=" letter-spacing:1.5px; font-weight: 700 !important; padding-left: 10px; font-size: 1.2em; ">
            Menú
          </v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-toolbar>
        
    <v-list dense>
      <template>
        <v-list-tile
          v-for="item in sidebarItems.items"
          :key="item.title"
          exact
          ripple
          active-class="accent"
          class="scoped-list-tile mb-1"
          :to="item.to"
          :disabled="!item.to"
        >
          <v-list-tile-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>{{ item.title }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </template>
      <template>
        <v-list dense style="padding-left:0.35rem !important;">
          <v-list-group
            v-for="item in sidebarItems.items1"
            :key="item.title"
            v-model="item.active"
            :prepend-icon="item.action"
            no-action
          >
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>

            <v-list-tile v-for="subItem in item.items" :key="subItem.title" :to="subItem.to">
              <v-list-tile-content>
                <v-list-tile-title style="font-size:11px;">{{ subItem.title }}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list-group>
        </v-list>
      </template>
      <template>
        <v-list-tile
          exact
          ripple
          active-class="accent"
          class="scoped-list-tile mb-1"
          @click="logout({ router: $router })"
        >
          <v-list-tile-action @click="logout({ router: $router })">
            <v-icon>power_settings_new</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>Cerrar sesión</v-list-tile-content>
        </v-list-tile>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      items1: [
        {
          action: "local_shipping",
          title: "Despacho",
          items: [
            { title: "Coberturas", to: { name: "listaCoberturas" } },
            { title: "Courier", to: { name: "listaCourier" } },
            { title: "Países y Ciudades", to: { name: "ListPais" } }
          ]
        },
        {
          action: "assessment",
          title: "Reportes",
          items: [
            { title: "Listado de Carros", to: { name: "reporteCarros" } },
            { title: "Stock de productos", to: { name: "reporteStock" } },
            { title: "Quiebre de stock", to: { name: "reporteSinStock" } },
            { title: "Productos más vendidos", to: { name: "reporteItems" } },
            { title: "Clientes más compradores", to: { name: "reporteClientes" } },
            { title: "Ciudades más populares", to: { name: "reporteCiudades" } }
          ]
        },
        {
          action: "settings",
          title: "Configuraciones",
          items: [
            { title: "Banner Principal", to: { name: "ListPortada" } },
            { title: "Etapas Carro", to: { name: "ListaEtapas" } },
            { title: "Clasificaciones Atributos", to: { name: "ListClasificacion" } },
            { title: "Etiquetas", to: { name: "ListaEtiqueta" } }
          ]
        }
      ],
      items: [
        {
          title: "Carros de Compras",
          icon: "shopping_cart",
          to: { name: "carts" }
        },
        {
          title: "Clientes",
          icon: "face",
          to: { name: "ListCliente" }
        },
        {
          title: "Productos",
          icon: "description",
          to: { name: "ListaProducto" }
        },
        {
          title: "Categorías",
          icon: "local_offer",
          to: { name: "ListaCategoria" }
        },
        {
          title: "Bodegas",
          icon: "store",
          to: { name: "ListaBodegas" }
        },
        {
          title: "Cupones",
          icon: "local_play",
          to: { name: "ListaCupones" }
        },
        {
          title: "Administradores",
          icon: "perm_identity",
          to: { name: "users" }
        }

        // {
        //   title: "Etapas",
        //   icon: "web",
        //   to: { name: "ListaEtapas" }
        // },
        // {
        //   title: "Clasificaciones",
        //   icon: "web",
        //   to: { name: "ListClasificacion" }
        // },
      ]
    };
  },
  computed: {
    ...mapState({
      primaryDrawer: state => state.layout.primaryDrawer,
      user: state => state.auth.user
    }),
    ...mapGetters({
      authenticated: "auth/check",
      sidebarItems: "layout/sidebarItems"
    })
  },

  methods: {
    ...mapActions({
      replacePrimaryDrawer: "layout/replacePrimaryDrawer",
      logout: "auth/logout"
    }),

    replacePrimaryDrawerMini() {
      let primaryDrawer = this.primaryDrawer;
      primaryDrawer.mini = !primaryDrawer.mini;

      this.replacePrimaryDrawer({ primaryDrawer });
    }
  }
};
</script>

<style lang="scss" scoped>
.drawer {
  background-color: primary !important;
  color: white !important;
}
.scoped-list-tile {
  overflow: hidden !important;
}
::-webkit-scrollbar {
     width: 2px;
}

::-webkit-scrollbar-track {
     background: #fff;
}

::-webkit-scrollbar-thumb {
    background-color: #999;
    border-radius: 10px;
}
</style>
